import { CategoryEnum, ActionsEnum } from '@academica-box/analytics/events/index'
import type { HomeClickCard, HomeClickLink, HomeClickShelf, HomeShowCard, HomeShowShelf } from '@academica-box/analytics/events/home'

export const sendHomeShowShelf = (label: HomeShowShelf['label'], context: HomeShowShelf['context']) => {
  const { $analytics } = useNuxtApp()
  $analytics?.push({
    category: CategoryEnum.Home,
    action: ActionsEnum.show_shelf,
    label,
    context,
  })
}

export const sendHomeClickShelf = (label: HomeClickShelf['label'], context: HomeClickShelf['context']) => {
  const { $analytics } = useNuxtApp()
  $analytics?.push({
    category: CategoryEnum.Home,
    action: ActionsEnum.click_shelf,
    label,
    context,
  })
}

export const sendHomeClickCard = (context: HomeClickCard['context']) => {
  const { $analytics } = useNuxtApp()
  $analytics?.push({
    category: CategoryEnum.Home,
    action: ActionsEnum.click_card,
    context,
  })
}

export const sendHomeClickLink = (label: HomeClickLink['label'], context: HomeClickLink['context']) => {
  const { $analytics } = useNuxtApp()
  $analytics?.push({
    category: CategoryEnum.Home,
    action: ActionsEnum.click_link,
    label,
    context,
  })
}

export const sendHomeShowCard = (context: HomeShowCard['context']) => {
  const { $analytics } = useNuxtApp()
  $analytics?.push({
    category: CategoryEnum.Home,
    action: ActionsEnum.show_card,
    context,
  })
}
